@charset "UTF-8";

@import "foundation/settings";

// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

// Make sure the charset is set appropriately

// Behold, here are all the Foundation components.
  @import "foundation/components/grid";
  @import "foundation/components/accordion";
  @import "foundation/components/alert-boxes";
  @import "foundation/components/block-grid";
  @import "foundation/components/breadcrumbs";
  @import "foundation/components/button-groups";
  @import "foundation/components/buttons";
  @import "foundation/components/clearing";
  @import "foundation/components/dropdown";
  @import "foundation/components/dropdown-buttons";
  @import "foundation/components/flex-video";
  @import "foundation/components/forms";
  @import "foundation/components/icon-bar";
  @import "foundation/components/inline-lists";
  @import "foundation/components/joyride";
  @import "foundation/components/keystrokes";
  @import "foundation/components/labels";
  @import "foundation/components/magellan";
  @import "foundation/components/orbit";
  @import "foundation/components/pagination";
  @import "foundation/components/panels";
  @import "foundation/components/pricing-tables";
  @import "foundation/components/progress-bars";
  @import "foundation/components/range-slider";
  @import "foundation/components/reveal";
  @import "foundation/components/side-nav";
  @import "foundation/components/split-buttons";
  @import "foundation/components/sub-nav";
  @import "foundation/components/switches";
  @import "foundation/components/tables";
  @import "foundation/components/tabs";
  @import "foundation/components/thumbs";
  @import "foundation/components/tooltips";
  @import "foundation/components/top-bar";
  @import "foundation/components/type";
  @import "foundation/components/offcanvas";
  @import "foundation/components/visibility";

.price-label {
  @include label(
          $padding: 10px 16px,
          $text-size: 36px,
          $bg: #eba620,
          $radius:20px
  );
  font-weight: 500;
}
